/*
  Entry point for the Arabic site
*/

$font-size-base: 1.1rem;
$h1-font-size: $font-size-base * 2.3;


@import 'main';


.navbar-brand {
  margin-right: 0;
  margin-left: $navbar-padding-x;
}

#utility-navigation {
  margin-right: auto;
  /* margin-left mirrors margin-right of the same element */
  margin-left: $header-hamburger-right + $hamburger-layer-width + $utility-nav-link-margin-x * 2 + .5rem - $navbar-padding-x;
}
#toggle-mega-sidebar.hamburger {
  right: auto;
  left: $header-hamburger-right;
}


#mega-sidebar {
  right: auto;
  left: 0;
}

#mega-sidebar-menu {
  .item > .section-title:first-child {
    margin-left: 0;
    margin-right: $toggle-arrow-space * 2 + .9rem; /* Mirrors margin-left of the same element */
  }

  .item [data-toggle].collapsed > .toggle {
    transform: rotate(90deg);
  }
}


/**
  Side by side sections
 */
.row[class*="side-by-side-"].border-separated > [class*="col-"] {
  &:first-child {
    border-right: none;
    border-left: $sbs-border;
  }
}

@include media-breakpoint-up(md) {
  .row[class*="side-by-side-"] > [class*="col-"] {
    &:first-child {
      text-align: left;
      padding-right: ($grid-gutter-width / 2); // Default Bootstrap's padding for columns
      padding-left: map-get($spacers, 5);
    }
    &:last-child {
      text-align: right;
      padding-left: ($grid-gutter-width / 2); // Default Bootstrap's padding for columns
      padding-right: map-get($spacers, 5);
    }
  }
}


// Plus icon on the "All Videos" buttons in the Videos section
.btn[data-toggle="collapse"]:before {
  margin-right: 0;
  margin-left: 0.2rem;
}


.spotlight .h1 { text-align: right }
@include media-breakpoint-up(md) {
  .spotlight .callout-buttons { text-align: right }
}


#sticky-footer .product-name {
  padding-left: 3rem;
  padding-right: 0;
}


html[dir="rtl"] {
  /* .text-left is used in the headlines, for example on the product pages */
  .text-left { text-align: right !important }
}


/* Forms */
.form-check {
  padding-left: 0;
  padding-right: $form-check-input-gutter;
}
.form-check-input {
  margin-left: 0;
  margin-right: -$form-check-input-gutter;
}


.utility-block {
  .item-callout {
    text-align: right;
    margin-left: 0;
    margin-right: 1.5rem;
  }
  .item-callout > span {
    margin-left: 0;
    margin-right: $font-size-base;
  }

  .svg-icon { transform: rotate(20deg) }
}


.social-links a:not(:last-child) { margin: 0 0 0 2rem }